import { FC } from 'react'
import { Avatar, CloseButton, HStack, VStack } from '@chakra-ui/react'
import { GrandHeading, GrandText } from 'ui'
import { useSlideOut } from '#/src/custom/controllers'
import { Currency, OrderDTO } from 'ecosystem'
import { getProductImages } from 'api'
import { commaSeparator } from 'shared-utils'
import { CustomerOrderItem, OrderGroupItem } from 'storefront-modules/account'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import { useTranslations } from 'next-intl'

type SlideOutCustomerOrderDetailOverrides = {
  currency?: string
}

export interface SlideOutCustomerOrderDetailProps {
  order: OrderDTO
}

const SlideOutCustomerOrderDetail: FC<SlideOutCustomerOrderDetailProps> = ({ order }) => {
  const overrides = useOverridesContext<keyof SlideOutCustomerOrderDetailOverrides>()
  const t = useTranslations('account.SlideOutCustomerOrderDetail')

  const { closeSlideOut } = useSlideOut()

  return (
    <VStack spacing={2} w="full">
      <CloseButton alignSelf="flex-end" onClick={closeSlideOut} />
      <GrandHeading textAlign="right" title={t('title')} />
      <CustomerOrderItem customerOrder={order} />

      <GrandHeading textAlign="right" title={t('deliveryAddress')} />
      <OrderGroupItem label={`${t('address')} 1`} value={order.shippingAddress?.address1} />
      <OrderGroupItem label={`${t('address')} 2`} value={order.shippingAddress?.address2} />
      <OrderGroupItem label={t('postCode')} value={order.shippingAddress?.postalCode} />
      <OrderGroupItem label={t('attention')} value={order.shippingAddress?.attention} />
      <OrderGroupItem label={t('mobile')} value={order.shippingAddress?.mobilePhone} />

      <GrandHeading textAlign="right" title={t('yourOrder')} />
      <VStack w="full">
        {order.items?.map((item, index) => {
          const { mainImageUrl } = getProductImages(item.product.medias)
          return (
            <VStack key={index} w="full">
              <HStack w="full">
                <Avatar flexGrow={0} src={mainImageUrl} />
                <GrandText fontWeight="bold">{item.product.name}</GrandText>
                <GrandText>{item.quantity} st.</GrandText>
                <GrandText>
                  {commaSeparator(item.unitPrice)} {overrideText(Currency.SEK, overrides?.currency)}
                </GrandText>
              </HStack>
            </VStack>
          )
        })}
      </VStack>
    </VStack>
  )
}

export default SlideOutCustomerOrderDetail
