import { CustomLocale, SharedPathnamesNav } from '#/src/i18n'
import { useModal } from '#/src/custom/controllers'
import { useTranslations } from 'next-intl'
import { useUser } from 'auth'
import { useCallback } from 'react'
import { LogoutConfirmationModalProps } from '#/src/custom/controllers/modals/ConfirmationMessage'

export const useLocaleSwitch = () => {
  const intlRouter = SharedPathnamesNav.useRouter()
  const { openModal } = useModal()
  const t = useTranslations('logout.Modals')
  const { user } = useUser()

  const handleChannelChange = useCallback(
    (locale: CustomLocale['locale']) => {
      if (!user?.isLoggedIn) {
        intlRouter.push('/', { locale, scroll: true })
        return
      }

      openModal<LogoutConfirmationModalProps>({
        title: t('title'),
        componentId: 'confirmation-message',
        extraArgs: {
          locale
        }
      })
    },
    [intlRouter, openModal, t, user]
  )

  return { handleChannelChange }
}
