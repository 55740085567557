import { type FC, useCallback } from 'react'
import { Button, Flex, Icon, VStack } from '@chakra-ui/react'
import { CiWarning } from 'react-icons/ci'
import EmptyMessage from 'ui/common/EmptyMessage'
import { useModal } from '#/src/custom/controllers'
import { fetchJson } from 'api'
import { useTranslations } from 'next-intl'
import { useUser } from 'auth'
import { CustomLocale, SharedPathnamesNav } from '#/src/i18n'

export interface LogoutConfirmationModalProps {
  locale: CustomLocale['locale']
}

const LogoutConfirmationModal: FC<LogoutConfirmationModalProps> = ({ locale }) => {
  const { closeModal } = useModal()
  const t = useTranslations('logout.Modals')
  const { mutateUser } = useUser()
  const intlRouter = SharedPathnamesNav.useRouter()

  const handleConfirmation = useCallback(async () => {
    await mutateUser(await fetchJson('/api/auth/logout', { method: 'POST' }), false)
    intlRouter.push('/', { locale, scroll: true })
    closeModal()
  }, [closeModal, intlRouter, locale, mutateUser])

  return (
    <VStack w="full" p={4} spacing={10}>
      <EmptyMessage
        icon={<Icon as={CiWarning} boxSize={12} color="yellow.400" />}
        text={t('message')}
        p={2}
        direction="column-reverse"
        textAlign="center"
        fontSize="sm"
      />
      <Flex w="full" justifyContent="flex-end">
        <Button variant="ghost" color="red.400" onClick={closeModal}>
          {t('cancelLabel')}
        </Button>
        <Button onClick={handleConfirmation}>{t('confirmationLabel')}</Button>
      </Flex>
    </VStack>
  )
}

export default LogoutConfirmationModal
