import { FC } from 'react'
import { Box, HStack, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import { useSelector } from 'shared-redux'
import Thumbnails from './Thumbnails'
import CategoryWithSubCategories from './CategoryWithSubCategories'
import { ContentContainer, GrandNextLink, GrandText } from 'ui'
import {
  selectedMenuCategories,
  selectedMenuCategory,
  selectedMenuCategoryId
} from 'shared-redux/state'
import { sortCategories } from 'shared-utils'
import useAppConfig from '#/src/custom/hooks/useAppConfig'

export interface ISlideOutCategoriesProps {}

const SlideOutCategories: FC<ISlideOutCategoriesProps> = () => {
  const category = useSelector(selectedMenuCategory)
  const menuCategories = sortCategories([...useSelector(selectedMenuCategories)])
  const parentCategoryId = useSelector(selectedMenuCategoryId)
  const appConfig = useAppConfig()

  return (
    <ContentContainer borderColor="primary" borderTopWidth="0.2px" minH="20vh" py={0} w="100%">
      <HStack align="flex-start" py={6} spacing={2} w="100%">
        <VStack flex={1} spacing={0}>
          <Box w="full">
            <GrandNextLink
              aria-label={'category ' + category?.toUpperCase()}
              fontSize="sm"
              href={`${appConfig.paths.categoriesUrlPath}/${parentCategoryId}`}>
              <GrandText pb={4}>{category?.toUpperCase()}</GrandText>
            </GrandNextLink>
          </Box>
          <Wrap spacing={4} w="full">
            {menuCategories.map?.((category, index) => (
              <WrapItem key={index + '-navCategoryGroup1-' + category?.id}>
                <CategoryWithSubCategories {...{ category }} />
              </WrapItem>
            ))}
          </Wrap>
        </VStack>

        <Thumbnails flex={0} {...{ menuCategories }} />
      </HStack>
    </ContentContainer>
  )
}

export default SlideOutCategories
