'use client'
import { FC } from 'react'
import { Grid, GridItem, GridProps } from '@chakra-ui/react'
import { CategoryDTO } from 'ecosystem'
import { GrandNextLink } from 'ui'
import { FeaturedCategoryItem } from 'storefront-modules/categories'
import useAppConfig from '#/src/custom/hooks/useAppConfig'

interface IThumbnailsProps extends GridProps {
  menuCategories: CategoryDTO[]
}

const Thumbnails: FC<IThumbnailsProps> = ({ menuCategories, ...props }) => {
  const appConfig = useAppConfig()
  const secondCategoryGroupSize = appConfig.topNavSlideOutFeaturedCategories

  return (
    <Grid gap={4} templateColumns="repeat(3, 1fr)" {...props}>
      {menuCategories.slice(0, secondCategoryGroupSize).map((category, index) => (
        <GridItem key={index + '-navCategoryFeatured-' + category?.id}>
          <GrandNextLink
            aria-label={category.name}
            href={`${appConfig.paths.categoriesUrlPath}/${category.slug}`}>
            <FeaturedCategoryItem
              cursor={{
                base: 'default',
                md: 'pointer'
              }}
              fontSize={{
                base: '5vw',
                md: '2vw'
              }}
              h="9vw"
              imageProps={{
                sizes: '9vw'
              }}
              item={category}
              px={0}
              w="9vw"
            />
          </GrandNextLink>
        </GridItem>
      ))}
    </Grid>
  )
}

export default Thumbnails
