import React, { FC, ReactElement } from 'react'
import ModalPhotoGallery, { IModalPhotoGalleryProps } from './modals/ModalPhotoGallery'
import ModalConfirmation, { IModalConfirmationProps } from './modals/ModalConfirmation'
import ModalGMaps, { IModalGMapsProps } from './modals/ModalGmaps'
import ModalCookies from './modals/ModalCookies'
import ModalContact, { ModalContactProps } from './modals/ModalContact'
import LogoutConfirmationModal, {
  LogoutConfirmationModalProps
} from '#/src/custom/controllers/modals/ConfirmationMessage'

type ModalChildrenDictionary = Record<ModalType, ReactElement>

interface IModalChildrenProps {
  componentId: ModalType | null
  extraArgs: unknown
}

export const ModalComponent: FC<IModalChildrenProps> = ({ componentId, extraArgs }) => {
  if (!componentId) return null
  // components are added to this dictionary, and to access them, you will need to use useModal hook to pass the
  // componentId to openModal
  const dictionary: ModalChildrenDictionary = {
    default: <div>DEV: no component selected</div>,

    confirmation: <ModalConfirmation {...(extraArgs as IModalConfirmationProps)} />,

    'confirmation-message': (
      <LogoutConfirmationModal {...(extraArgs as LogoutConfirmationModalProps)} />
    ),

    openPhotoGallery: <ModalPhotoGallery {...(extraArgs as IModalPhotoGalleryProps)} />,

    gmaps: <ModalGMaps {...(extraArgs as IModalGMapsProps)} />,

    contact: <ModalContact {...(extraArgs as ModalContactProps)} />,

    cookiePolicy: <ModalCookies />
  }

  return dictionary[componentId] ?? dictionary.default
}
