import { Box } from '@chakra-ui/react'
import { ProductListItemBuy } from 'storefront-modules/products'
import { AssistantV2 } from 'storefront-modules/assistant'
import React, { ReactNode } from 'react'
import { DeliveryDTO, Language, Option } from 'ecosystem'
import { useCustomCart } from '#/src/custom/cart/useCustomCart'
import useProductRequest from '#/src/custom/products/useProductRequest'
import useAppConfig from '#/src/custom/hooks/useAppConfig'
import { CustomLocale } from '#/src/i18n'

const teaserContent = (language: Language) => {
  const translations: Record<CustomLocale['locale'], ReactNode> = {
    sv: (
      <>
        Hej! Jag är här för att hjälpa dig. <br />
        Vad kan jag göra för dig?
      </>
    ),
    no: (
      <>
        Hei! Jeg er her for å hjelpe deg. <br />
        Hva kan jeg gjøre for deg?
      </>
    )
  }

  return translations[language as CustomLocale['locale']]
}

export interface CustomAssistantProps {
  deliveries?: Option<DeliveryDTO[]>
}

export const CustomAssistant = ({ deliveries }: CustomAssistantProps) => {
  const appConfig = useAppConfig()
  const { handleAddToCart } = useCustomCart()
  const { handleProductRequest } = useProductRequest()

  return (
    <AssistantV2
      paths={{
        chat: '/api/assistant/v2/chat',
        thread: '/api/assistant/v2/thread',
        textToSpeech: '/api/assistant/v2/text-to-speech',
        settings: '/api/assistant/settings',
        products: appConfig.paths.productsUrlPath
      }}
      teaser={{
        mobileMode: 'hint',
        desktopMode: 'hint',
        delay: 5000,
        content: teaserContent(appConfig.language)
      }}
      components={{
        teaser: {
          props: {
            contentProps: {
              sx: {
                '--popper-bg': '#ebedd6',
                '--popper-arrow-bg': 'var(--popper-bg)',
                '--popper-arrow-shadow-color': '#cacfa0'
              },
              borderColor: 'var(--popper-arrow-shadow-color)',
              color: '#333333'
            }
          }
        },
        price: {
          render: ({ product }) => (
            <>
              {!!product.originalProduct && (
                <Box
                  sx={{
                    '.product-list-item-buy': {
                      '&__button-wrapper': {
                        position: 'static'
                      }
                    }
                  }}
                  w="100%">
                  <ProductListItemBuy
                    priceRequestButtonProps={{
                      onClick: (e) => e.stopPropagation()
                    }}
                    buttonProps={{
                      onClick: (e) => e.stopPropagation()
                    }}
                    price={{
                      price: product.originalProduct.price,
                      originalPrice: product.originalProduct.originalPrice
                    }}
                    product={product.originalProduct}
                    deliveryOptions={deliveries ?? null}
                    productsUrlPath={appConfig.paths.productsUrlPath}
                    onBuy={(item) => {
                      handleAddToCart({ ...item, qty: '1' })
                    }}
                    onProductRequest={handleProductRequest}
                  />
                </Box>
              )}
            </>
          )
        }
      }}
    />
  )
}
