import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys)

const variantResponsive = definePartsStyle({
  popper: {
    maxWidth: {
      base: 'unset',
      md: '500px'
    }
  },
  content: {
    width: {
      base: '100vw',
      md: '500px'
    }
  }
})

const variants = {
  responsive: variantResponsive
}

export default defineMultiStyleConfig({
  variants
})
