'use client'
import { FC } from 'react'
import { SlideOutComponent, SlideOutFooter } from '#/src/custom/controllers/SlideOutChildren'
import { SlideOutContext, SlideOutStoreController, useSlideOut } from '..'
import { useSelector } from 'shared-redux'
import { selectIsWindowOnTop } from 'shared-redux/state'
import useAppConfig from '#/src/custom/hooks/useAppConfig'

const SlideOutHandler: FC = () => {
  const { componentId, extraArgs, footerId } = useSlideOut()
  const isWindowOnTop = useSelector(selectIsWindowOnTop)
  const appConfig = useAppConfig()

  return (
    <SlideOutStoreController
      context={SlideOutContext}
      footer={<SlideOutFooter {...{ footerId }} />}
      topGap={isWindowOnTop ? appConfig.topNavHeight.spacer : appConfig.topNavHeight.content}>
      <SlideOutComponent {...{ componentId, extraArgs }} />
    </SlideOutStoreController>
  )
}

export default SlideOutHandler
