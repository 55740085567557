import { useDispatch, useSelector } from 'shared-redux'
import { FC, useCallback, useMemo } from 'react'
import { Button, VStack } from '@chakra-ui/react'
import { GrandAccordion, GrandHeading, IAccordionItem } from 'ui'
import { useSlideOut } from '#/src/custom/controllers'
import { commaSeparator } from 'shared-utils'

import {
  selectedProductState,
  setSelectedPriceRange,
  setSelectedSorting,
  toggleFilterBrand
} from 'shared-redux/state'
import { FilterRangeSelector, useUpdateSearch } from 'storefront-modules/products'
import {
  FilterBrands,
  FilterItems,
  FilterMobileSorting
} from 'storefront-modules/products/components/product-list'
import { Currency, SortingOption } from 'ecosystem'
import useAppConfig from '#/src/custom/hooks/useAppConfig'

export interface ISlideOutMoreFiltersProps {}

const SlideOutMoreFilters: FC<ISlideOutMoreFiltersProps> = () => {
  const appConfig = useAppConfig()
  const dispatch = useDispatch()
  const { closeSlideOut } = useSlideOut()
  const productsState = useSelector(selectedProductState)
  const {
    availableFilters,
    brandFilterOptions,
    selectedFilterBrandIds: selectedBrandIds,
    sortOrder,
    sortBy,
    sortLabel,
    priceRangeFilter: priceRange
  } = productsState

  const selectedSorting = { sortBy, sortOrder, sortLabel }
  const { updateSearch, isLoading, clearFilters } = useUpdateSearch(
    productsState,
    appConfig.sliderDefaultValues,
    appConfig.sortingOptions
  )

  // ********************** VIEW HANDLERS ***********************
  const handleSorting = useCallback(
    async (selection: SortingOption) => {
      dispatch(setSelectedSorting(selection))
    },
    [dispatch]
  )

  const handleBrands = useCallback(
    async (brandId: string) => {
      dispatch(toggleFilterBrand({ brandId }))
    },
    [dispatch]
  )

  const handleUpdateSearch = () => {
    updateSearch()
    closeSlideOut()
  }

  // ********************** VIEW CONFIG ***********************
  const accordionItems: IAccordionItem[] = useMemo(() => {
    const filters = availableFilters.map((filter) => ({
      id: filter.name,
      title: filter.name,
      component: (
        <FilterItems
          sliderMaxPrice={appConfig.sliderMaxPrice}
          sortingOptions={appConfig.sortingOptions}
          {...{ filter, productsState }}
        />
      )
    }))
    return [
      ...(!!brandFilterOptions?.length
        ? [
            {
              id: 'brands',
              title: 'Varumärken',
              component: (
                <FilterBrands
                  onChange={handleBrands}
                  {...{ selectedBrandIds, brandFilterOptions }}
                />
              )
            }
          ]
        : []),
      ...filters
    ]
  }, [
    appConfig.sliderMaxPrice,
    appConfig.sortingOptions,
    availableFilters,
    brandFilterOptions,
    handleBrands,
    productsState,
    selectedBrandIds
  ])

  return (
    <VStack h="full" justify="space-between" w="full">
      <VStack spacing={6} w="full">
        <VStack w="full">
          <GrandHeading headingTag="h6" title="Sort" />
          <FilterMobileSorting
            onChange={handleSorting}
            sortingOptions={appConfig.sortingOptions}
            {...{ selectedSorting }}
          />
        </VStack>
        <VStack w="full">
          <GrandHeading headingTag="h6" title="Filters" />
          <VStack w="full">
            <GrandHeading fontSize="lg" fontWeight="bold" headingTag="h6" title="Pris" />
            <FilterRangeSelector
              currentMaxValue={commaSeparator(priceRange[1])}
              currentMinValue={commaSeparator(priceRange[0])}
              px={0}
              rangeProps={{
                value: priceRange,
                defaultValue: appConfig.sliderDefaultValues,
                onChange: (values) => dispatch(setSelectedPriceRange(values as [number, number]))
              }}
              sliderMaxPrice={appConfig.sliderMaxPrice}
              unitsLabel={Currency.SEK}
              w="full"
            />
          </VStack>
        </VStack>

        <GrandAccordion allowMultiple items={accordionItems} w="full" />
      </VStack>

      <VStack align="flex-end" w="full">
        <Button
          _focus={{
            boxShadow: 'none',
            outline: 'none'
          }}
          _hover={{
            bg: 'transparent'
          }}
          aria-label="Rensa all filtrering"
          color="text.mild"
          fontWeight="medium"
          onClick={clearFilters}
          size="xs"
          variant="ghost">
          Rensa all filtrering
        </Button>
        <Button aria-label="Sök" onClick={handleUpdateSearch} w="full" {...{ isLoading }}>
          Sök
        </Button>
      </VStack>
    </VStack>
  )
}

export default SlideOutMoreFilters
